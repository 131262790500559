import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://383385bbbb774864a5d48d0812f0ca97@o453826.ingest.sentry.io/5496552',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['https://inception.klassroom.io', 'https://my.klassroom.io', 'https://tfs.klassroom.io','https://horton.klassroom.io'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 1.0,

});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
