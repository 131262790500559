import { DatePipe, DecimalPipe, KeyValuePipe, PercentPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { setAppInjector } from '@configs/app-injector';
import { globalLanguageSpecific } from '@configs/global-language-specific-code';
import { REST_API, REST_NO_BASE, REST_NEW_BASE, RestApiFactory, RestNoBaseFactory, RestNewBaseFactory } from '@configs/rest.config';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
globalLanguageSpecific();

@NgModule({
  declarations: [AppComponent],
  imports: [SweetAlert2Module.forRoot(),FormsModule,BrowserModule, HttpClientModule, AppRoutingModule, BrowserAnimationsModule, SimpleNotificationsModule.forRoot()],
  providers: [
    DatePipe,
    KeyValuePipe,
    DecimalPipe,
    PercentPipe,
    {
      provide: ErrorHandler,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: REST_API, useFactory: RestApiFactory },
    { provide: REST_NO_BASE, useFactory: RestNoBaseFactory },
    { provide: REST_NEW_BASE, useFactory: RestNewBaseFactory},
    { provide: LOCALE_ID, useValue: 'en-US' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector, trace: Sentry.TraceService) {
    setAppInjector(injector);
  }
}
