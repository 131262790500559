import { environment } from '@env/environment';
import{School}from '../models/school'
const isDev = environment.mode !== 'prod';


//We'll call inception's school name my for now, just so it finds the url while we haven't updated it
export const schools:School[]=[new School('my',isDev ? 'https://dev.api.klassroom.io/' : 'https://api.klassroom.io/',
isDev ? 'https://dev.my.klassroom.io' : 'https://my.klassroom.io',
'6o0gmvvojnmevpduqivupdvvln',
'1ri5km954pgtp37jscufgn0t2ic9n3svph2tsssaacdtqpudiuv0',
`inception.auth.klassroom.io`
),
new School('inception', 'https://api.klassroom.io/',
'https://inception.klassroom.io',
'6o0gmvvojnmevpduqivupdvvln',
'1ri5km954pgtp37jscufgn0t2ic9n3svph2tsssaacdtqpudiuv0',
`inception.auth.klassroom.io`
),
new School('qa', 'https://dev.api.klassroom.io/',
'https://qa.klassroom.io',
'1p8eft17ut35k94ohnbl0tn5s6',
'1pkiual59eci30nd1bre2gf0q33kn47koivrj5oitage9p7l2fmv',
'klassroom-dev.auth.us-east-1.amazoncognito.com'
),
new School('horton',`https://api.klassroom.io/`,
`https://horton.klassroom.io`,
'3k55m59f0jtqvh122730ljlsql',
'4fbvoj34tuflkulppijk872f556pu7re3vq50hfv2mggs3jsn9',
`horton.auth.klassroom.io`
)
,
new School('tfs', 'https://api.klassroom.io/',
'https://tfs.klassroom.io',
'1oaht9fl6fe086tmddm96ulg3n',
'1s6s096bn18fouvnem6e6r7b78nmp5jssoe0rkig5qlkn9vf2036',
'tfs.auth.klassroom.io'
),
new School('localhost',`https://dev.api.klassroom.io/`,
`http://localhost:11000`,
'1p8eft17ut35k94ohnbl0tn5s6',
'1pkiual59eci30nd1bre2gf0q33kn47koivrj5oitage9p7l2fmv',
'klassroom-dev.auth.us-east-1.amazoncognito.com'
),
new School('demo',`https://api.klassroom.io/`,
`https://demo.klassroom.io`,
'5knj3i2v7mndki86ekmk061pg3',
'1ttmig015e7f3fjmshd63laonu0egdb3jbcq1h29csg3eq20mnit',
`demo.auth.klassroom.io`
),
new School('slim',`https://api.klassroom.io/`,
`https://slim.klassroom.io`,
'9h0a18nghof5tk5s78rg1pq3m',
'qvn8ig2vkfpb6e1bf9qnnb9iv9co717fred5etb8sihdd989dgm',
`slim.auth.us-east-1.amazoncognito.com`
),

];
