import { Component, OnInit } from '@angular/core';
import { ComponentParent } from '@utils/components-parent.component';
import { Options } from 'angular2-notifications';

@Component({
  selector: 'clsrm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ComponentParent implements OnInit {
  simpleNotifOptions: Options = { timeOut: 3000, maxStack: 3, preventDuplicates: true, clickToClose: true, pauseOnHover: true };
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
