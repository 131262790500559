import { AnyObject } from '@models/utils';

class ObjectUtilsClass {
  convertObjToArrValues<T>(obj: { [key: string]: T }) {
    return Object.keys(obj).map(key => obj[key]);
  }
  convertObjToArrKeyValue<T>(obj: { [key: string]: T }) {
    return Object.keys(obj).map(key => ({ key: key, value: obj[key] }));
  }

  renameObjKeys<T extends AnyObject>(_obj: T, keys: Partial<{ [oldKey in keyof T]: string }>) {
    const obj = { ..._obj };
    Object.keys(keys).forEach(oldKey => {
      const newKey = keys[oldKey]!;
      if (obj.hasOwnProperty(oldKey)) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }
    });
    return obj;
  }

  removeObjKeys<T extends AnyObject>(_obj: T, keys: (keyof T)[]): AnyObject {
    return Object.keys(_obj).reduce((res, k) => {
      if (keys.includes(k)) {
        return { ...res };
      } else {
        return { ...res, [k]: _obj[k] };
      }
    }, {});
  }

  cloneObjWithKeys<T extends {}>(obj: T, keys: (keyof T)[]) {
    return keys.reduce((res, k) => ({ ...res, [k]: obj[k] }), {});
  }

  cloneObjectToObject<T>(source: T, target: T, { copyNulls }: { copyNulls: boolean } = { copyNulls: true }) {
    Object.keys(source).forEach(key => {
      if (source[key] == undefined) return;
      target[key] = source[key];
    });
  }

  fillEmptyValues(array: any[], values: string[], rowGetter: (x: any) => any = x => x.splited, valueGetter = x => x, fillWith = 0) {
    for (const r of array) {
      for (const val of values) {
        const value = valueGetter(val);
        const row = rowGetter(r);
        if (!row[value]) {
          row[value] = fillWith;
        }
      }
    }
  }
  removeNullKeysFromObject<T>(obj: T): Required<T> {
    Object.keys(obj).forEach(key => obj[key] == undefined && delete obj[key]);
    return obj as Required<T>;
  }
}

export const ObjectUtils = new ObjectUtilsClass();
