import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { AppInjector } from '@configs/app-injector';
import { TokenService } from '@services/token.service';
import { UrlUtils } from '@utils/url.utils';
import { empty, MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, share, shareReplay } from 'rxjs/operators';
import { ObjectUtils } from '@utils/object.utils';
import { ErrorHandlerService } from '@services/error-handler.service';
import { schools } from '@constants/rest.constants';
import { ServerModel } from '@models/server-models';
interface RequestInfo {
  method: RequestMethod;
  fullRequestUrl: string;
  body: RequestBody;
  requestParams: RequestParams;
  headers: HttpHeaders;
}

export class RestService {
  private readonly CACHE_EXPIRE = 2 * 60 * 1000; // ms

  private cacheStorage = new Map<string, { requestTime: number; /* response?: string; */ request?: Observable<any> }>();
  private createCacheKey({ method, fullRequestUrl, body, requestParams, headers }: RequestInfo): string {
    return JSON.stringify({
      method,
      fullRequestUrl,
      body,
      requestParams,
      headers: headers.keys().reduce((res, key) => ({ ...res, key: headers.getAll(key) }), {}),
    });
  }
  getCached<T = any>(requestInfo: RequestInfo) {
    const cacheKey = this.createCacheKey(requestInfo);
    const cachedObj = this.cacheStorage.get(cacheKey);
    if (cachedObj && cachedObj.requestTime + this.CACHE_EXPIRE > Date.now()) {
      if (cachedObj.request) return cachedObj.request as Observable<T>;
    }
    return undefined;
  }
  saveCachedRequest<T>(requestInfo: RequestInfo, requestObj: Observable<T>) {
    const cacheKey = this.createCacheKey(requestInfo);
    this.cacheStorage.set(cacheKey, { request: requestObj, requestTime: Date.now() });
  }
  clearCachedRequest<T>(requestInfo: RequestInfo) {
    const cacheKey = this.createCacheKey(requestInfo);
    this.cacheStorage.delete(cacheKey);
  }

  constructor(private restConfig: RestConfig) {}

  one(...paths: string[]) {
    return new RestRequest(this, this.restConfig, UrlUtils.concatPaths(...paths));
  }
}

export class RestRequest {
  private http: HttpClient = AppInjector.get<HttpClient>(HttpClient);
  private token: TokenService = AppInjector.get<TokenService>(TokenService);
  private errorHandlerService = AppInjector.get<ErrorHandlerService>(ErrorHandlerService);

  private requestHeaders?: HttpHeadersObj;
  private requestParams?: RequestParams;
  private body?: RequestBody;
  private _additionalOptions?: RequestOptions;
  public get additionalOptions(): Required<RequestOptions> {
    return { responseType: 'json', observe: 'body', reportProgress: false, ...this._additionalOptions };
  }

  method?: RequestMethod;
  request?: Observable<any>;
  allPathsUrl = '';
  get fullRequestUrl(): string {
    return this.restConfig.basePath ? `${this.restConfig.basePath}${this.allPathsUrl}` : this.allPathsUrl;
  }

  constructor(private restService: RestService, private restConfig: RestConfig, path?: string) {
    this.allPathsUrl = UrlUtils.concatPaths(this.allPathsUrl, path);
  }

  // one(...paths: string[]) {
  //   const urlpath = UrlUtils.concatPaths(this.allPathsUrl, UrlUtils.concatPaths(...paths));
  //   return new RestRequest(this.restService, this.restConfig, urlpath);
  // }

  private enrichRestRequestInstance<T = any>(
    method: RequestMethod,
    headers: HttpHeadersObj | undefined,
    params: RequestParams | undefined,
    body?: any,
    additionalOptions?: RequestOptions,
  ) {
    this.requestHeaders = headers;
    this.requestParams = params;
    if (body) this.body = body;
    this._additionalOptions = { ...this.additionalOptions, ...additionalOptions };
    this.request = this.buildOrGetRequest(method);
    return this.request;
  }

  post<T = any>(
    body: RequestBody = {},
    params?: RequestParams,
    headers?: HttpHeadersObj,
    additionalOptions?: RequestOptions,
  ): Observable<T> {
    return this.enrichRestRequestInstance<T>('post', headers, params, body, additionalOptions);
  }
  put<T = any>(
    body: RequestBody = {},
    params?: RequestParams,
    headers?: HttpHeadersObj,
    additionalOptions?: RequestOptions,
  ): Observable<T> {
    return this.enrichRestRequestInstance<T>('put', headers, params, body, additionalOptions);
  }
  patch<T = any>(
    body: RequestBody = {},
    params?: RequestParams,
    headers?: HttpHeadersObj,
    additionalOptions?: RequestOptions,
  ): Observable<T> {
    return this.enrichRestRequestInstance<T>('patch', headers, params, body, additionalOptions);
  }
  get<T = any>(params?: RequestParams, headers?: HttpHeadersObj, additionalOptions?: RequestOptions): Observable<T> {
    return this.enrichRestRequestInstance<T>('get', headers, params, undefined, additionalOptions);
  }
  delete<T = any>(params?: RequestParams, headers?: HttpHeadersObj, additionalOptions?: RequestOptions): Observable<T> {
    return this.enrichRestRequestInstance<T>('delete', headers, params, additionalOptions);
  }

  private buildOrGetRequest<T = any>(method: RequestMethod): Observable<T> {
    this.method = method;

    const cachedVersion = this.restService.getCached<T>({ ...this.getRequestInfoObj(), method });
    if (cachedVersion) {
      return cachedVersion;
    }

    let resultRequest: Observable<T>;
    resultRequest = this.createNewRequestInstance<T>(method);
    resultRequest = this.allRequestsEnrich(resultRequest);
    return resultRequest;
  }

  private createNewRequestInstance<T = any>(method: string): Observable<T> {
    return this.http.request<T>(method, this.fullRequestUrl, {
      body: this.body,
      headers: this.getHeaders(),
      params: this.getParams(),
      responseType: this.additionalOptions.responseType,
      reportProgress: this.additionalOptions.reportProgress,
      observe: this.additionalOptions.observe as any,
    }) as any; // request type overloads are too confusing and it usually results in a wrong type casting
  }

  private allRequestsEnrich<T>(request: Observable<T>): Observable<T> {
    request = this.setupErrorHandlerPipe<T>(request);
    if (this.restConfig.cache) {
      request = this.setupCachePipes(request);
    }
    return request;
  }

  private setupCachePipes<T>(request: Observable<T>) {
    request = request.pipe(share());
    if (this.restConfig.cache === 'all') {
      request = request.pipe(this.cacheResponsePipe(request));
      this.restService.saveCachedRequest(this.getRequestInfoObj(), request);
    } else if (!this.restConfig.cache!.method || this.restConfig.cache!.method === this.method) {
      if (!this.restConfig.cache!.requestOnly) {
        request = request.pipe(this.cacheResponsePipe(request));
      }
      this.restService.saveCachedRequest(this.getRequestInfoObj(), request);
    }
    return request;
  }

  private getRequestInfoObj(): RequestInfo {
    return {
      method: this.method!,
      fullRequestUrl: this.fullRequestUrl,
      body: this.body,
      requestParams: this.getParams(),
      headers: this.getHeaders(),
    };
  }
  findActiveSchool = (): number => {
    let index:number=-1;
    for(let i=0;i<schools.length;i++){
      if( window.location.href.toString().toLowerCase().includes(schools[i].name)){
        index=i;
      }
    }
    return index;
  }
  private setupErrorHandlerPipe<T>(request: Observable<T>) {
    let selectedSchool:number;
    selectedSchool=this.findActiveSchool();
    return request.pipe<T>(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && (error.error.message === 'Unauthorized' || error.error.error === 'Unauthorized.') && !error.error.details && this.token.refreshToken) {
          const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', this.token.refreshToken)
            .set('client_id',schools[selectedSchool].CLIENT_ID);
          const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', `Basic ${btoa(`${schools[selectedSchool].CLIENT_ID}:${schools[selectedSchool].CLIENT_SECRET}`)}`);

          return this.http
            .post<ServerModel.OAuth2Response>(`https://${schools[selectedSchool].AUTH_DOMAIN}/oauth2/token`, body.toString(), { headers })
            .pipe(
              catchError(error => {
                this.token.logout();
                return empty();
              }),
              map(data => this.token.handleCognitoInfo(data)),
              mergeMap(() => this.buildOrGetRequest<T>(this.method!)),
            );
        }
        const isHandled = this.errorHandlerService.handleError(error);
        if (isHandled) return empty();
        return throwError(error);
      }),
    );
  }

  private cacheResponsePipe<T>(request: Observable<T>): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => {
      return source.pipe(
        shareReplay(3), // 3 is the size of buffer, not the number of shares!
        map(data => (this.additionalOptions.responseType === 'json' ? JSON.parse(JSON.stringify(data)) : data)),
        catchError((response: HttpErrorResponse) => {
          this.restService.clearCachedRequest(this.getRequestInfoObj());
          return throwError(response);
        }),
      );
    };
  }

  private getHeaders(): HttpHeaders {
    const requestHeaders = ObjectUtils.removeNullKeysFromObject(this.requestHeaders || {});
    if (this.restConfig.header === 'AUTH') return new HttpHeaders({ ...this.getAuthenticationHeader(), ...requestHeaders });
    return new HttpHeaders(requestHeaders);
  }
  private getParams(): HttpParams {
    const requestParams = ObjectUtils.removeNullKeysFromObject(this.requestParams || {});
    return new HttpParams({ fromObject: requestParams, encoder: new CustomQueryParamsEncoder() });
  }

  getAuthenticationHeader() {
    return this.token.getAuthenticationHeader();
  }
}

export type RequestBody = any;
export interface RequestParams {
  [key: string]: any;
}

export interface HttpHeadersObj {
  'Content-Type'?: 'application/x-www-form-urlencoded' | string;
  Authorization?: string;
}

export interface RequestOptions {
  responseType?: 'json' | undefined;
  reportProgress?: boolean;
  observe?: 'events' | 'body';
}
// export type RestType = 'ANALYZE' | 'OAUTH' | 'NOT_AUTH' | 'AUTH' | 'ADMIN';
export interface RestConfig {
  cache?: 'all' | { method?: 'get'; requestOnly: boolean };
  basePath: string;
  header?: 'AUTH';
}

export type RequestMethod = 'post' | 'get' | 'put' | 'delete' | 'patch';

class CustomQueryParamsEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
