import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';
import { schools } from '@constants/rest.constants';
import { map } from 'rxjs/operators';
import { CourseApiService } from '@api/course-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard implements CanActivate {
  constructor(
    private router: Router,
    private token: TokenService,
    private route: ActivatedRoute,
    private courseApiService: CourseApiService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.token.isLoggedIn()) {
      return true;
    }

    if (next.queryParams.code) {
      return this.getTokens(next.queryParams.code);
    }
  
    let index:number=-1;
    for(let i=0;i<schools.length;i++){
      if( window.location.href.toString().toLowerCase().includes(schools[i].name)){
        index=i;
      }
    }

    
    
    location.href =schools[index]. LOGIN_PAGE;
    return false;
  }

  private getTokens(code: string) {
    return this.courseApiService.getTokens(code).pipe(
      map(data => {
        this.token.handleCognitoInfo(data);
        return this.router.createUrlTree(['/'], { preserveFragment: false, preserveQueryParams: false });
      }),
    );
  }
}
