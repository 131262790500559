declare global {
  interface Array<T> {
    isEmpty(): boolean;
    findAndUpdateIfExists(predicate: (value: T, index?: number, obj?: T[]) => boolean, updateWith: T, thisArg?: any): boolean;
    getLast(): T | undefined;
  }
}
export const globalLanguageSpecific = () => {
  if (!Array.prototype.isEmpty) {
    Array.prototype.isEmpty = function <T>(this: T[]): boolean {
      if (!this) return false;
      return this.length === 0;
    };
  }

  if (!Array.prototype.findAndUpdateIfExists) {
    Array.prototype.findAndUpdateIfExists = function <T>(
      this: T[],
      predicate: (this: void, value: T, index?: number, obj?: T[]) => boolean,
      updateWith: T,
      thisArg?: any,
    ): boolean {
      if (!this || !this.findIndex || !predicate) return false;
      const index = this.findIndex(predicate);
      if (index !== -1) {
        this[index] = updateWith;
        return true;
      }
      return false;
    };
  }

  if (!Array.prototype.getLast) {
    Array.prototype.getLast = function <T>(this: T[]): T | undefined {
      if (this.length <= 0) return undefined;
      return this[this.length - 1];
    };
  }
};
