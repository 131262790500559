class StringUtilsClass {
  getFullName({ given_name, family_name }: { given_name?: string; family_name?: string }) {
    return given_name && family_name ? `${given_name} ${family_name}` : given_name ? given_name : family_name ? family_name : '';
  }
  copyToClipboard = (str: string) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  getBetterFileName(fileName: string) {
    const fileExtension = fileName.split('.').pop() ?? '';
    fileName = fileName.replace(/[^a-zA-Z0-9\-.]/gi, '-');
    return fileName.length < 3 ? `file-${fileName}` : fileName.length > 100 ? `${fileName.substr(0, 99)}.${fileExtension}` : fileName;
  }

  fileNameExtension(fileName: string) {
    return fileName.split('.').pop();
  }
}

export const StringUtils = new StringUtilsClass();
