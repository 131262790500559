import { InjectionToken } from '@angular/core';
import { schools } from '@constants/rest.constants';
import { RestService } from '@services/rest.service';

export const REST_API = new InjectionToken<RestService>('RestApi');
export function RestApiFactory() {
  let selectedIndex:number;
  
  
    let index:number=-1;
    for(let i=0;i<schools.length;i++){
      schools[i].name
      if( window.location.href.toString().toLowerCase().includes(schools[i].name)){
        index=i;
      }
    }
    selectedIndex=index;
  return new RestService({ basePath:schools[selectedIndex].API_BASE_URL, header: 'AUTH' });
}

export const REST_NEW_BASE = new InjectionToken<RestService>('RestNewBase');
export function RestNewBaseFactory() {
  let selectedIndex:number;
  let index:number=-1;
  for(let i=0;i<schools.length;i++){
    schools[i].name
    if( window.location.href.toString().toLowerCase().includes(schools[i].name)){
      index=i;
    }
  }
  selectedIndex=index;
  const version = '1';

  return new RestService({ 
    basePath:(schools[selectedIndex].API_BASE_URL.includes('dev') ? `https://staging.api.klassroom.io/v${version}/`: `https://api.klassroom.io/v${version}/`),
    header: 'AUTH' 
  });
}

export const REST_NO_BASE = new InjectionToken<RestService>('RestNoBase');
export function RestNoBaseFactory() {
  return new RestService({ basePath: '' });
}
