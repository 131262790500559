class UrlUtilsClass {
  private _concatPaths(path1: string | number = '', path2: string | number = '') {
    path1 = (path1 || '').toString();
    path2 = (path2 || '').toString();
    path1 = this.removeTrailingSlashes(path1);
    path2 = this.removeTrailingSlashes(path2);

    let result = path2 ? `${path1}/${path2}` : `${path1}`;
    // result = result.replace(/\/\//g, '/');
    result = this.removeTrailingSlashes(result);
    return result;
  }
  concatPaths(...paths: (string | number | undefined)[]): string {
    return paths.reduce<string>((res, p) => this._concatPaths(res, p), '');
  }

  removeTrailingSlashes(path: string): string {
    return (path || '').replace(/^\/+|\/+$/g, '');
  }

  convertObjectToURLParams(obj: { [key: string]: string }) {
    const params = new URLSearchParams();
    Object.keys(obj).forEach(key => {
      params.set(key, obj[key]);
    });
    return params;
  }

  getHashQueryParams<R>(fragment: string): R | false {
    if (!fragment) return false;
    try {
      const elementsString = decodeURIComponent(fragment);
      return elementsString.split('&').reduce<R>((res, param) => {
        const values = param.split('=');
        return { ...res, [values[0]]: values[1] };
      }, {} as R);
    } catch (e) {
      return false;
    }
  }
}

export const UrlUtils = new UrlUtilsClass();
