export class School {
 name:string;
 API_BASE_URL :string;
 REDIRECT_URI:string;
 CLIENT_ID:string;
 CLIENT_SECRET:string;
 AUTH_DOMAIN:string;
 LOGIN_PAGE:string;
 LOGOUT_PAGE:string;
constructor(name:string,API_BASE_URL:string,REDIRECT_URI:string,CLIENT_ID:string,CLIENT_SECRET:string,AUTH_DOMAIN:string){
    this.name=name.toString().toLowerCase();
    this.API_BASE_URL=API_BASE_URL;
this.REDIRECT_URI=REDIRECT_URI;
this.CLIENT_ID=CLIENT_ID;
this.CLIENT_SECRET=CLIENT_SECRET;
this.AUTH_DOMAIN=AUTH_DOMAIN;
this.LOGIN_PAGE = `https://${AUTH_DOMAIN}/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=openid+profile`;
this.LOGOUT_PAGE = `https://${AUTH_DOMAIN}/logout?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=openid+profile`;

}
}

