import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerMessage, ErrorHandlerMessageObj, ErrorMessages } from '@models/errors';
import { PageService } from '@services/page.service';
import { Router } from '@angular/router';
import { TokenService } from '@services/token.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private readonly errorHandlerMessages: ErrorMessages = {
    // 10006: 'نام کاربری یا رمز عبور غلط است',
  };

  private extractErrorHandlerMessageObj(errorHandlerMsg: ErrorHandlerMessage): ErrorHandlerMessageObj & { handled: boolean } {
    return typeof errorHandlerMsg === 'string' ? { message: errorHandlerMsg, handled: true } : { handled: true, ...errorHandlerMsg };
  }

  private httpErrorHandler({ error, status }: HttpErrorResponse): boolean {
    let message = 'Unknown error. Please refresh the page';
    let handled = false;
    let notifyType: ErrorHandlerMessageObj['notifyType'];
    let funcDoAfter: ErrorHandlerMessageObj['funcDoAfter'];

    if (status === 500) {
      message = 'Unknown error. Please refresh the page';
      handled = false;
    } else if (status === 401 && error.message === 'Unauthorized' && !error.details) {
      this.tokenService.logout();
      return true;
    } else {
      const constantMsg = this.errorHandlerMessages[error.message] || undefined;
      if (constantMsg) {
        const obj = this.extractErrorHandlerMessageObj(constantMsg);
        message = obj.message;
        handled = obj.handled;
        notifyType = obj.notifyType;
        funcDoAfter = obj.funcDoAfter;
      } else if (error.details) {
        message = error.details;
      } else if (error.message) {
        message = error.message;
      }
    }

    this.sendErrorMessage(message, notifyType, funcDoAfter);
    return handled;
  }

  constructor(public page: PageService, private router: Router, private tokenService: TokenService) {}

  sendErrorMessage(
    errorMsg: ErrorHandlerMessageObj['message'],
    notifyType?: ErrorHandlerMessageObj['notifyType'],
    funcDoAfter?: ErrorHandlerMessageObj['funcDoAfter'],
  ) {
    if (notifyType) this.page.notify(notifyType, errorMsg);
    else this.page.error(errorMsg);

    if (funcDoAfter) funcDoAfter();
  }

  handleError(error: HttpErrorResponse): boolean {
    if (!this.isHttpErrorResponse(error)) {
      this.sendErrorMessage('Unknown error. Please refresh the page');
      return false;
    }

    return this.httpErrorHandler(error);
  }

  private isHttpErrorResponse(error: any): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse || (!error.error && typeof error.status === 'number');
  }
}
