import { Injectable } from '@angular/core';
import { schools } from '@constants/rest.constants';
import { AccessTokenDecoded, IdTokenDecoded, SchoolInfo, UserInfo, UserType } from '@models/auth';
import { LocalStorageService } from '@utils/local-storage';
import * as JwtDecode from 'jwt-decode';
import { ServerModel } from '@models/server-models';
import { StringUtils } from '@utils/string.utils';
import {CourseApiService} from'../api/course-api.service'
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private LS = new LocalStorageService('token');
  get refreshToken(): string | undefined {
    return this.LS.get('refresh_token');
  }
  private set _refreshToken(curr: string | undefined) {
    this.LS.set('refresh_token', curr);
  }
  get accessToken(): string | undefined {
    return this.LS.get('access_token');
  }
  private set _accessToken(curr: string | undefined) {
    this.LS.set('access_token', curr);
  }
  get idToken(): string | undefined {
    return this.LS.get('id_token');
  }
  private set _idToken(curr: string | undefined) {
    this.LS.set('id_token', curr);
  }
  get userInfo(): UserInfo | undefined {
    return this.LS.get('user_info');
  }
  private set _userInfo(curr: UserInfo | undefined) {
    this.LS.set('user_info', curr);
  }
  get schoolInfo(): SchoolInfo | undefined {
    return this.LS.get('school_info');
  }
  private set _schoolInfo(curr: SchoolInfo | undefined) {
    this.LS.set('school_info', curr);
  }

  get clientId(): string | undefined {
    return this.LS.get('client_id');
  }

  private set _clientId(curr: string | undefined) {
    this.LS.set('client_id', curr);
  }


  constructor() {

  }

  handleCognitoInfo(data: ServerModel.OAuth2Response) {
    const { id_token, access_token, refresh_token } = data;
    const idTokenInfo: IdTokenDecoded = JwtDecode(id_token);
    const accessTokenInfo: AccessTokenDecoded = JwtDecode(access_token);

    const username = idTokenInfo['cognito:username'] || accessTokenInfo.username;
    const type = this.getUserType(...idTokenInfo['cognito:groups'], ...accessTokenInfo['cognito:groups'])[0];
    const { iss, email, given_name: givenName, family_name: familyName } = idTokenInfo;
    const schoolId = iss.split('/').pop();
    const clientId = idTokenInfo.aud;

    this._clientId = clientId;
    this._refreshToken = refresh_token ?? this.refreshToken;
    this._accessToken = access_token;
    this._idToken = id_token;
    this._userInfo = {
      email,
      username,
      type,
      familyName,
      givenName,
      fullName: StringUtils.getFullName({ given_name: givenName, family_name: familyName }),
    };
    this._schoolInfo = schoolId ? { schoolId } : undefined;
    return true;
  }

  private getUserType(...groupsArr: UserType[]) {
    return (groupsArr || []).filter(g => (['role_student', 'role_teacher','role_admin'] as UserType[]).includes(g));
  }
  findActiveSchool = (): number => {
    let index:number=-1;
    for(let i=0;i<schools.length;i++){
      if( window.location.href.toString().toLowerCase().includes(schools[i].name)){
        index=i;
      }
    }
    return index;
  }
  logout() {
    let selectedSchool;

    selectedSchool=this.findActiveSchool();
    this._accessToken = undefined;
    this._idToken = undefined;
    this._userInfo = undefined;
    location.href =schools[selectedSchool]. LOGOUT_PAGE;
    this.LS.clear();
  }

  getAuthenticationHeader() {
    return { Authorization: `Bearer ${this.idToken ?? ''}` };
  }

  isLoggedIn(): boolean {
    return !!this.idToken && !!this.userInfo?.type && !!this.refreshToken && !!this.schoolInfo?.schoolId;
  }

  getSchoolUrl() {
    return this.schoolInfo?.schoolId ? `https://klassroom-public-files.s3.amazonaws.com/logos/${this.schoolInfo?.schoolId}.png` : undefined;
  }

  getClientId(){
    return this.clientId ? this.clientId : undefined;
  }
}
