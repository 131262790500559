import { Inject, Injectable, isDevMode } from '@angular/core';
import { REST_API, REST_NEW_BASE, REST_NO_BASE } from '@configs/rest.config';
import { RestService } from '@services/rest.service';
import { ServerModel } from "@models/server-models";
import { HttpParams } from '@angular/common/http';
import { schools } from '../constants/rest.constants';
import {tap} from "rxjs/operators";
import {UserInfo} from "@models/auth";
import UserInformation = ServerModel.UserInformation;
import {
  courseReport,
  CourseUnitResponse,
  reportCardResponse,
  studentReportEnrollmentResponse,
  studentReportResponse,
  studentsReport,
  UserReportResponse, UserReportResponse2,
  userReportS1Body,
  userReportS2Body,
  SchoolInfoResponse, CoursesAPLResponse, CoursesAPLResponseWithSession, UserCourseAPL, UserCourseAPLResponse
} from "@models/course";
import addUnitDutyRequest = ServerModel.addUnitDutyRequest;
import getUnitResponse = ServerModel.getUnitResponse;
import thisUserInformation = ServerModel.thisUserInformation;
import CourseInformation = ServerModel.CourseInformation;
import UserWithAttendanceResponse = ServerModel.UserWithAttendanceResponse;

@Injectable({
  providedIn: 'root',
})
export class CourseApiService {
  constructor(@Inject(REST_API) private rest: RestService, @Inject(REST_NO_BASE) private restNoBase: RestService, @Inject(REST_NEW_BASE) private restNewBase: RestService) {}
  selectedSchool?:number;
  getTokens(code: string) {
    this.selectedSchool=this.findActiveSchool();
    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id',schools[this.selectedSchool].CLIENT_ID)
      .set('redirect_uri', schools[this.selectedSchool].REDIRECT_URI)
      .set('code', code);
    return this.restNoBase.one(`https://${schools[this.selectedSchool].AUTH_DOMAIN}/oauth2/token`).post<ServerModel.OAuth2Response>(body.toString(), undefined, {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${btoa(`${schools[this.selectedSchool].CLIENT_ID}:${schools[this.selectedSchool].CLIENT_SECRET}`)}`,
    });
  }

getSchoolIndex(){
  return this.selectedSchool;
}
  findActiveSchool = (): number => {
    let index:number=-1;
    for(let i=0;i<schools.length;i++){
      if( window.location.href.toString().toLowerCase().includes(schools[i].name)){
        index=i;
      }
    }
    return index;
  }
  getAllEvents() {
    return this.rest.one('courses-a/events').get<ServerModel.GetEventsResponse>();
  }
  getCourses() {
    return this.restNewBase.one(`courses?withTeachers=true&withAttendance=true`).get<ServerModel.CoursesResponse>();
  }
  getCoursesForStudent(){
    return this.rest.one(`courses-a`).get<ServerModel.CoursesResponse>();
  }
  getUnits(courseCode: string) {
    return this.restNewBase.one(`courses/${courseCode}/units?ng=true&withItems=true`).get<CourseUnitResponse>();
  }
  getCourseReportsInCourse(courseId: string,term: string){
    return this.restNewBase.one(`courses/${courseId}/reports/${term}`).get<studentsReport>();
  }
  getUserReportEnrolments(userId: string, term: string){
    return this.restNewBase.one(`users/${userId}/enrolments/reports/${term}`).get<studentReportEnrollmentResponse>();
  }
  getUserReport(userId: string, reportId: string){
    return this.restNewBase.one(`users/${userId}/reports/${reportId}`).get<UserReportResponse2>();
  }

  listUserReports(userId: string, term: string){
    return this.restNewBase.one(`users/${userId}/reports/?term=${term}&status=unsigned`).get<UserReportResponse>();
  }

  updateCourseReport(studentReport: courseReport,courseId: string,term: string,userId: string){
    return this.restNewBase.one(`courses/${courseId}/reports/${term}/${userId}`).put(studentReport);
  }

  updateS1Report(body: userReportS1Body, reportId: string,userId: string){
    return this.restNewBase.one(`users/${userId}/reports/${reportId}/1`).put(body);
  }

  updateS2Report(body: userReportS2Body,reportId: string,userId: string){
    return this.restNewBase.one(`users/${userId}/reports/${reportId}/2`).put(body);
  }

  updateS3Report(reportId: string,userId: string){
    return this.restNewBase.one(`users/${userId}/reports/${reportId}/3`).put();
  }

  createUserReport(body: {term: string}, userId){
    return this.restNewBase.one(`/users/${userId}/reports`).post(body)
  }

  getSchoolInfo(){
    return this.restNewBase.one(`/school`).get<SchoolInfoResponse>()
  }

  sendMessage(userId:string,subject: string,message:string,role: string | undefined,courseId?:string){
    let body ={
      title: subject,
      body: message
    }
    // console.log(userId,subject,message,role,courseId)
    if (role == 'admin'){
      return this.restNewBase.one(`users/${userId}/email`).post(body);
    }
    if (role == 'teacher'){
      return this.restNewBase.one(`users/${userId}/email?course=${courseId}`).post(body);
    }
    return;
  }
  getUnitDetails(courseCode: string, unitId: string) {
    return this.rest.one(`courses-b/${courseCode}/units/${unitId}/items`).get<ServerModel.UnitItemsResponse>();
  }
  addItem(courseCode: string, unitId: string, body: ServerModel.AddItemBody) {
    return this.restNewBase.one(`courses/${courseCode}/units/${unitId}/items`).post<ServerModel.AddItemResponse>(body);
  }

  getAllStudents(courseCode: string){
    return this.restNewBase.one(`admin/${courseCode}/users?role=student`).get<ServerModel.GetUsersResponse>();
  }

  getStudentGradebook(courseCode: string, userName: string){
    return this.restNewBase.one(`admin/${courseCode}/gradebook/${userName}`).get<ServerModel.GetGradebookResponse>()
  }

  listUserDocuments(userId: string){
    return this.restNewBase.one(`users/${userId}/documents`).get<ServerModel.UserDoucmentsResponse>()
  }

  addDocumentToUserRequest(userId: string, fileName: string){
    return this.restNewBase.one(`users/${userId}/documents`).post<ServerModel.UserAddDocumentResponse>({fileName: fileName})
  }

  getUserDocumentUrl(userId: string, documentId: string): any {
    return this.restNewBase.one(`users/${userId}/documents/${documentId}`).get<any>()
  }

  deleteUserDocument(userId: string, documentId: string){
    return this.restNewBase.one(`users/${userId}/documents/${documentId}`).delete<any>()
  }

  getUsersList(cursor: string){
    return this.restNewBase.one(`users?cursor=${cursor}`).get<ServerModel.Users>()
  }

  getUserInfo(userId: string){
    return this.restNewBase.one(`users/${userId}`).get<ServerModel.UserDetail>()
  }
  getUserCourses(userId: string){
    return this.restNewBase.one(`users/${userId}/enrolments`).get<ServerModel.Courses>(params => {cursor: ''})
  }
  getCourseUsers(courseId: string,cursor: string){
    return this.restNewBase.one(`users?course=${courseId}&cursor=${cursor}`).get<ServerModel.courseUsersDetail>()
  }
  deleteUserCourse(userId,CourseId){
    return this.restNewBase.one(`users/${userId}/enrolments/${CourseId}`).delete<ServerModel.DeleteItemResponse>();
  }
  inactivateUser(userId,CourseId){
    return this.restNewBase.one(`users/${userId}/enrolments/${CourseId}/active`).delete<ServerModel.DeleteItemResponse>();
  }
  activateUser(userId,CourseId){
    return this.restNewBase.one(`users/${userId}/enrolments/${CourseId}/active`).put();
  }

  deleteUserFromSchool(userId){
    return this.restNewBase.one(`users/${userId}`).delete<ServerModel.DeleteItemResponse>();
  }
  registerUser(UserInformation){
    return this.restNewBase.one(`users`).post<ServerModel.addUserResponse>(UserInformation);
  }
  registerCourse(CourseInformation){
    return this.restNewBase.one(`courses`).post(CourseInformation);
  }
  deleteCourse(courseId){
    return this.restNewBase.one(`courses/${courseId}`).delete();
  }
  duplicateCourse(CourseInformation,CourseId){
    return this.restNewBase.one(`courses?from=${CourseId}`).post<ServerModel.DuplicateResponse>(CourseInformation);
  }
  getThisUser(){
    return this.restNewBase.one(`users/@me`).get<thisUserInformation>();
  }
  updateUser(UserInformation,userId){
    return this.restNewBase.one(`users/${userId}`).patch(UserInformation);
  }
  deleteSubmission(courseId,unitId,itemId,submitionId){
    return this.restNewBase.one(`/courses/${courseId}/units/${unitId}/items/${itemId}/submissions/${submitionId}`).delete()
  }
  bulkUserToCourse(list,userId){
    return this.restNewBase.one(`users/${userId}/enrolments`).patch(list);
  }
  bulkCourseToUsers(list,CourseId){
    return this.restNewBase.one(`courses/${CourseId}/enrolments`).patch(list);

  }

  updateCourse(courseCode: string, body: { desc: string }) {
    return this.rest.one(`courses-a/${courseCode}`).put(body);
  }
  updateCourseInfo(Course: CourseInformation, CourseId: string){
    return this.restNewBase.one(`courses/${CourseId}`).patch(Course);
  }

  getItemDetails(courseCode: string, unitId: string, itemId: string) {
    return this.restNewBase.one(`courses/${courseCode}/units/${unitId}/items/${itemId}?withAttachments=true`).get<ServerModel.ItemResponse>();
  }

  deleteItem(courseCode: string, unitId: string, itemId: string) {
    return this.rest.one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}`).delete<ServerModel.DeleteItemResponse>();
  }
  editItem(courseCode: string, unitId: string, itemId: string, body: ServerModel.EditItemBody) {
    return this.restNewBase.one(`courses/${courseCode}/units/${unitId}/items/${itemId}`).put<ServerModel.EditItemResponse>(body);
  }
  editUnit(courseCode: string, unitId: string, body: addUnitDutyRequest) {
    return this.restNewBase.one(`courses/${courseCode}/units/${unitId}`).patch(body);
  }
  getCourseAttendance(courseId: string){
    return this.restNewBase.one(`courses/${courseId}?withAttendance=true`).get<CoursesAPLResponse>();

  }
  getUserAttendance(courseId: string){
    return this.restNewBase.one(`courses/${courseId}/attendance/@me`).get<UserCourseAPLResponse>();
  }
  getCourseWithSessionAttendance(courseId: string,date: string){
    return this.restNewBase.one(`courses/${courseId}?withAttendance=true&attendanceSessionDate=${date}`).get<CoursesAPLResponseWithSession>();

  }
  getStudentsAttendance(courseId: string){
    return this.restNewBase.one(`users?course=${courseId}&withAttendance=true&only=student`).get<UserWithAttendanceResponse>();
  }

  putStudentsAttendance(body,courseId:string,userId: string,date:string){
    return this.restNewBase.one(`courses/${courseId}/attendance/${userId}/session/${date}`).put(body);
    // /courses/:courseId/attendance/:userId/session/:date

  }


    deleteUnit(courseCode: string, unitId: string) {
    return this.restNewBase.one(`courses/${courseCode}/units/${unitId}`).delete();
  }

  getItemFile(courseCode: string, unitId: string, itemId: string, fileId: string) {
    return this.rest.one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/files/${fileId}`).get<ServerModel.GetItemFileResponse>();
  }
  getItemScorm(courseCode: string, unitId: string, itemId: string, fileId: string) {
    return this.rest.one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/files/${fileId}`).get<ServerModel.GetItemScormResponse>();
  }
  getItemVideo(courseCode: string, unitId: string, itemId: string, fileId: string) {
    return this.rest.one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/files/${fileId}`).get<ServerModel.GetItemVideoResponse>();
  }

  getGrades(courseCode: string) {
    return this.rest
      .one(`courses-a/${courseCode}/grades`)
      .get<ServerModel.GetGradesStudentResponse | ServerModel.GetGradesTeacherResponse>();
  }

  uploadFile(url: string, formData: FormData) {
    return this.restNoBase.one(url).post<ServerModel.FileUploadedResponse>(formData).pipe(
      tap(x => console.log(x))
    );
  }

  getItemSubmissions<T extends ServerModel.TeacherSubmissionsResponse | ServerModel.StudentSubmissionsResponse>(
    courseCode: string,
    unitId: string,
    itemId: string,
  ) {
    return this.rest.one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs`).get<ServerModel.GetSubmissionsResponse<T>>();
  }
  submitQuiz(courseCode: string, unitId: string,itemId: string,subId: string,body:ServerModel.quiz_response2){
    return this.rest
    .one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs`)
    .post<ServerModel.quiz_response2>(body)
  }
  getSubmissionDetails(courseCode: string, unitId: string, itemId: string, subId: string) {
    return this.rest
      .one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs/${subId}`)
      .get<ServerModel.GetSubmissionDetailsResponse>();
  }
  addTimedTestSubmission(courseCode: string, unitId: string, itemId: string, subId: string, body: ServerModel.UploadingFileBody) {
    return this.rest
      .one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs/${subId}`)
      .post<ServerModel.AddItemSubmissionResponse<ServerModel.AddItemSubmission>>(body);
  }
  addItemSubmission<T extends ServerModel.AddItemSubmission | ServerModel.StartTestResponse>(
    courseCode: string,
    unitId: string,
    itemId: string,
    body: ServerModel.UploadingFileBody,
  ) {
    return this.rest
      .one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs`)
      .post<ServerModel.AddItemSubmissionResponse<T>>(body).pipe(
        tap(x => console.log(x)));
  }

  sendEmailToInstructor(courseCode: string, body: ServerModel.SendEmailBody) {
    return this.rest.one(`courses-a/${courseCode}/email`).post<ServerModel.SendEmailBody>(body);
  }

  getCourseDetails(courseCode: string) {
    return this.restNewBase.one(`courses/${courseCode}?withUnits=true&withEvents=true`).get<ServerModel.GetCourseDetailsResponse>();
  }
  getCourseAnnouncements(courseCode: string) {
    return this.rest.one(`courses-a/${courseCode}/announcements`).get<ServerModel.GetCourseAnnouncementsResponse>();
  }

  addAdministrivia(courseCode: string, body: ServerModel.UploadingFileBody) {
    return this.rest.one(`courses-a/${courseCode}/administrivia`).post<ServerModel.AddAdministriviaResponse>(body);
  }

  deleteAdministrivia(courseCode: string, administriviaId: string) {
    return this.rest.one(`courses-a/${courseCode}/administrivia/${administriviaId}`).delete<ServerModel.DeleteAdministriviaResponse>();
  }

  getAdministrivia(courseCode: string, administriviaId: string) {
    return this.rest.one(`courses-a/${courseCode}/administrivia/${administriviaId}`).get<ServerModel.GetAdministriviaResponse>();
  }

  addAnnouncement(courseCode: string, body: ServerModel.AddAnnouncementBody) {
    return this.rest.one(`courses-a/${courseCode}/announcements`).post<ServerModel.AddAnnouncementResponse>(body);
  }

  editAnnouncement(courseCode: string, id: string, body: ServerModel.AddAnnouncementBody) {
    return this.rest.one(`courses-a/${courseCode}/announcements/${id}`).put<ServerModel.AddAnnouncementResponse>(body);
  }

  deleteAnnouncement(courseCode: string, id: string) {
    return this.rest.one(`courses-a/${courseCode}/announcements/${id}`).delete<ServerModel.DeleteAnnouncementResponse>();
  }

  getAllThreads(courseCode: string) {
    return this.rest.one(`discussions/${courseCode}/threads`).get<ServerModel.GetThreadsResponse>();
  }

  createThread(courseCode: string, body: ServerModel.CreateThreadBody) {
    return this.rest.one(`discussions/${courseCode}/threads`).post<ServerModel.CreateThreadResponse>(body);
  }

  updateThread(courseCode: string, threadId: string, body: ServerModel.CreateThreadBody) {
    return this.rest.one(`discussions/${courseCode}/threads/${threadId}`).put<ServerModel.nullResponse>(body);
  }

  deleteThread(courseCode: string, threadId: string) {
    return this.rest.one(`discussions/${courseCode}/threads/${threadId}`).delete<ServerModel.nullResponse>();
  }

  getThread(courseCode: string, threadId: string) {
    return this.rest.one(`discussions/${courseCode}/threads/${threadId}`).get<ServerModel.GetThreadResponse>();
  }

  postToThread(courseCode: string, threadId: string, body: ServerModel.PostToThreadBody) {
    return this.rest.one(`discussions/${courseCode}/threads/${threadId}/comments`).post<ServerModel.PostToThreadResponse>(body);
  }
  addUnitDuty(courseId: string,body: addUnitDutyRequest) {
    return this.restNewBase.one(`/courses/${courseId}/units`).post(body);
  }
  getCourseUnits(courseId: string) {
    return this.restNewBase.one(`/courses/${courseId}/units`).get<getUnitResponse>();
  }

  updateComment(courseCode: string, threadId: string, commentId: string, body: ServerModel.PostToThreadBody) {
    return this.rest.one(`discussions/${courseCode}/threads/${threadId}/comments/${commentId}`).put<ServerModel.nullResponse>(body);
  }

  deleteComment(courseCode: string, threadId: string, commentId: string) {
    return this.rest.one(`discussions/${courseCode}/threads/${threadId}/comments/${commentId}`).delete<ServerModel.nullResponse>();
  }

  createReply(courseCode: string, threadId: string, commentId: string, body: ServerModel.ReplyBody) {
    return this.rest
      .one(`discussions/${courseCode}/threads/${threadId}/comments/${commentId}/replies`)
      .post<ServerModel.ReplyResponse>(body);
  }

  updateReply(courseCode: string, threadId: string, commentId: string, replyId: string, body: ServerModel.ReplyBody) {
    return this.rest
      .one(`discussions/${courseCode}/threads/${threadId}/comments/${commentId}/replies/${replyId}`)
      .put<ServerModel.nullResponse>(body);
  }

  deleteReply(courseCode: string, threadId: string, commentId: string, replyId: string) {
    return this.rest
      .one(`discussions/${courseCode}/threads/${threadId}/comments/${commentId}/replies/${replyId}`)
      .delete<ServerModel.nullResponse>();
  }

  updateSubmissionGrade(courseCode: string, unitId: string, itemId: string, subId: string, body: ServerModel.UpdateSubGradeBody) {
    return this.rest
      .one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs/${subId}`)
      .put<ServerModel.UpdateSubGradeResponse>(body);
  }

  getSubmissionFile(courseCode: string, unitId: string, itemId: string, subId: string, fileId: string) {
    return this.rest
      .one(`/courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs/${subId}/files/${fileId}`)
      .get<ServerModel.GetItemFileResponse>();
  }

getSubmisstionQuiz(courseCode: string, unitId: string, itemId: string,subId:string ) {
  return this.rest
  .one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs/${subId}`)
    .get<ServerModel.ItemResponse2>();
}

  deleteFeedbackAttachment(courseCode: string, unitId: string, itemId: string, subId: string, fileId: string) {
    return this.rest
      .one(`/courses-b/${courseCode}/units/${unitId}/items/${itemId}/subs/${subId}/feedback/${fileId}`)
      .delete<ServerModel.DeleteFeedbackFileResponse>();
  }

  deleteItemAttachment(courseCode: string, unitId: string, itemId: string, fileId: string) {
    return this.rest
      .one(`courses-b/${courseCode}/units/${unitId}/items/${itemId}/files/${fileId}`)
      .delete<ServerModel.DeleteItemFileResponse>();
  }
}
